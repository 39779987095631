import {
  defineCustomElement,
  MediaVideoLayoutElement,
  MediaPosterElement,
  MediaPlayButtonElement,
  MediaControlsElement,
  MediaTimeSliderElement,
} from 'vidstack/elements'

import 'vidstack/player/styles/default/theme.css'
import 'vidstack/player/styles/default/layouts/video.css'

import 'vidstack/player'
import 'vidstack/player/ui'
import 'vidstack/icons'
import 'vidstack/player/layouts'

import { defineComponent } from '~/scripts/utils/alpine'

defineCustomElement(MediaVideoLayoutElement)
defineCustomElement(MediaPosterElement)
defineCustomElement(MediaPlayButtonElement)
defineCustomElement(MediaControlsElement)
defineCustomElement(MediaTimeSliderElement)

export default defineComponent(() => ({
  init() {},
}))
