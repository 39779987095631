import Alpine from 'alpinejs'
import ui from './ui'
import toast from './toast'

export default function register() {
  Alpine.store('ui', ui)
  Alpine.store('toast', toast)
}

declare global {
  type Store = {
    ui: typeof ui
    toast: typeof toast
  }

  interface Window {
    $store?: Store
  }
}
