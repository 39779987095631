import gsap from 'gsap'
import { defineComponent } from '~/scripts/utils/alpine'

export default defineComponent(() => ({
  hidden: [] as HTMLElement[],
  init() {
    this.hidden = [
      ...this.$refs.testimonials.querySelectorAll<HTMLElement>(
        '.testimonial.hidden',
      ),
    ]
  },
  get canShowMore() {
    return this.hidden.length > 0
  },
  showMoreTestimonials() {
    // Get up to 2 hidden testimonials to show
    const newTestimonials = this.hidden.slice(0, 2)

    const timeline = gsap.timeline({
      defaults: { ease: 'power3.inOut', duration: 0.5 },
    })

    // hide temporarily the show more button
    timeline.to(this.$el, { opacity: 0, y: 50 })

    if (newTestimonials.length === 0) return // no more element to show

    timeline.fromTo(
      newTestimonials,
      {
        opacity: 0,
        x: (index) => (index % 2 === 0 ? 200 : -200),
      },
      {
        opacity: 1,
        x: 0,
        stagger: 0.3,
        onStart: () => {
          gsap.set(newTestimonials, { display: 'block', opacity: 0 })

          // Update the hidden testimonials (remove the ones just shown from the list)
          this.hidden = this.hidden.filter(
            (testimonial) => !newTestimonials.includes(testimonial),
          )
        },
        onComplete: () => {
          // Show back the button if there are still hidden testimonials
          if (this.canShowMore) {
            gsap.to(this.$el, {
              opacity: 1,
              y: 0,
              duration: 1,
              ease: 'power3.inOut',
            })
          }
        },
      },
    )
  },
}))
