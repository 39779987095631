// from https://georgefrancis.dev/writing/create-a-liquid-hover-effect-with-gsap-and-svg/

import {
  spline,
  pointsInPath,
  createCoordsTransformer,
  // @ts-expect-error non-typed module
} from '@georgedoescode/generative-utils'

import gsap from 'gsap'
import MotionPathPlugin from 'gsap/MotionPathPlugin'

import { defineComponent } from '~/scripts/utils/alpine'
import { useElementSize } from '~/scripts/composables/useElementSize'
import screens from '~/config/screens.json'

gsap.registerPlugin(MotionPathPlugin)

interface Point {
  x: number
  y: number
}

const options = {
  detail: 48, // number of points
  tension: 1, // between 0 and 1
  close: true, // tells the spline function if our new path data should be a closed shape
  range: {
    // max distance from the origin point and the mouse
    x: 20,
    y: 20,
  },
  // move points along the x and or y axis
  axis: ['y'],
}

export default defineComponent(() => ({
  transformCoords: undefined as ((event: MouseEvent) => Point) | undefined,
  originPoints: [] as Point[],
  liquidPoints: [] as Point[],
  pointDistance: 0,
  maxDistribution: { x: 0, y: 0 },
  renderFn: undefined as (() => void) | undefined,
  countResetAnimations: 0,
  init() {
    useElementSize(this.$root, ({ width, height }) =>
      this.onResize({ width, height }),
    )

    if (this.isTouch()) return

    window.addEventListener('mousemove', this.onMouseMove.bind(this))

    this.$watch('countResetAnimations', (value: number) => {
      if (value === 0 && this.renderFn) {
        gsap.ticker.remove(this.renderFn)
        this.renderFn = undefined
      }
    })
  },
  isTouch() {
    return window.matchMedia(screens.touch.raw).matches
  },
  onMouseMove(event: MouseEvent) {
    if (!this.transformCoords) return
    const { x, y } = this.transformCoords(event)
    const mousePos = { x, y }
    mousePos.x = x
    mousePos.y = y

    for (const [index, point] of this.liquidPoints.entries()) {
      const pointOrigin = this.originPoints[index]
      const distributionX = Math.abs(pointOrigin.x - mousePos.x)
      const distributionY = Math.abs(pointOrigin.y - mousePos.y)

      if (
        distributionX <= options.range.x &&
        distributionY <= options.range.y
      ) {
        if (!this.renderFn) {
          this.renderFn = gsap.ticker.add(() => {
            gsap.set(this.$refs.buttonPath, {
              attr: {
                d: spline(this.liquidPoints, options.tension, options.close),
              },
            })
          })
        }
        const difference = {
          x: pointOrigin.x - mousePos.x,
          y: pointOrigin.y - mousePos.y,
        }

        const target = {
          x: pointOrigin.x + difference.x,
          y: pointOrigin.y + difference.y,
        }

        const x = gsap.utils.clamp(
          pointOrigin.x - this.maxDistribution.x,
          pointOrigin.x + this.maxDistribution.x,
          target.x,
        )

        const y = gsap.utils.clamp(
          pointOrigin.y - this.maxDistribution.y,
          pointOrigin.y + this.maxDistribution.y,
          target.y,
        )

        gsap.to(point, {
          x,
          y,
          ease: 'sine',
          overwrite: true,
          duration: 0.175,
          onComplete: () => {
            gsap.to(point, {
              x: pointOrigin.x,
              y: pointOrigin.y,
              ease: 'elastic.out(1, 0.3)',
              duration: 1.25,
              onStart: () => {
                this.countResetAnimations++
              },
              onComplete: () => {
                this.countResetAnimations--
              },
              onInterrupt: () => {
                this.countResetAnimations--
              },
            })
          },
        })
      }
    }
  },
  onResize({ width, height }: { width: number; height: number }) {
    const { rectPath, buttonPath } = this.$refs

    if (!rectPath || !buttonPath) return

    // do not initialize if no size
    if (width === 0 || height === 0) {
      return
    }

    rectPath.setAttribute('width', String(width))
    rectPath.setAttribute('height', String(height))

    if (!(rectPath instanceof SVGRectElement)) {
      return
    }

    // convert the rect path to path
    // we start from a <rect> in order to have border radius
    const generatedPath = MotionPathPlugin.convertToPath(rectPath, false)

    if (generatedPath.length === 0) {
      return
    }

    buttonPath.setAttribute('d', generatedPath[0].getAttribute('d')!)

    const svgPoints = pointsInPath(buttonPath, options.detail)
    this.originPoints = svgPoints.map(({ x, y }: Point) => ({ x, y }))
    this.liquidPoints = svgPoints.map(({ x, y }: Point) => ({ x, y }))

    this.transformCoords = createCoordsTransformer(buttonPath.closest('svg'))

    const pointDistance = Math.hypot(
      this.originPoints[0].x - this.originPoints[1].x,
      this.originPoints[0].y - this.originPoints[1].y,
    )
    this.maxDistribution = {
      x: options.axis.includes('x') ? pointDistance / 2 : 0,
      y: options.axis.includes('y') ? pointDistance / 2 : 0,
    }
  },
}))
