import Alpine from 'alpinejs'

import Button from './Button'
import Contest from './Contest'
import HelperBreakpoints from './HelperBreakpoints'
import SectionHero from './SectionHero'
import SectionServices from './SectionServices'
import SectionTestimonials from './SectionTestimonials'
import SectionDoctors from './SectionDoctors'
import SectionInstagram from './SectionInstagram'
import SiteHeader from './SiteHeader'
import SiteMenu from './SiteMenu'
import YoutubePlayer from './YoutubePlayer'
import WaveLeft from './WaveLeft'

export default function register() {
  Alpine.data('HelperBreakpoints', HelperBreakpoints)
  Alpine.data('SiteHeader', SiteHeader)
  Alpine.data('Button', Button)
  Alpine.data('Contest', Contest)
  Alpine.data('SectionHero', SectionHero)
  Alpine.data('SectionServices', SectionServices)
  Alpine.data('SectionTestimonials', SectionTestimonials)
  Alpine.data('SectionDoctors', SectionDoctors)
  Alpine.data('SectionInstagram', SectionInstagram)
  Alpine.data('SiteMenu', SiteMenu)
  Alpine.data('YoutubePlayer', YoutubePlayer)
  Alpine.data('WaveLeft', WaveLeft)
}
