import { Magics } from 'alpinejs'

function useStore(scope: Magics<object>): Store
function useStore(scope?: undefined): Window['$store']
function useStore(scope: Magics<object> | undefined): Store | Window['$store'] {
  if (scope !== undefined) return scope.$store as Store
  return window.$store
}

export { useStore }
