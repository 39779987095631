let _index = 0

export interface Toast {
  id: number
  type: 'success' | 'info' | 'warning' | 'error'
  title?: string
  message: string
  duration?: number
  image?: { src: string; alt?: string; width?: number; height?: number }
}

export interface ToastWithError extends Toast {
  error: Error
}

const toast = {
  list: [] as Array<Toast | ToastWithError>,
  add(toast: Omit<Toast, 'id'> | Omit<ToastWithError, 'id'>) {
    const newToast = { duration: 5000, ...toast, id: _index++ }
    this.list = [...this.list, newToast]
    setTimeout(() => {
      this.remove(newToast.id)
    }, newToast.duration)
    return newToast.id
  },
  remove(id: number) {
    this.list = this.list.filter((item) => item.id !== id)
  },
}

declare module 'alpinejs' {
  interface Stores {
    toast: typeof toast
  }
}

export default toast
