import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { defineComponent } from '~/scripts/utils/alpine'

export default defineComponent(() => ({
  init() {
    gsap.registerPlugin(ScrollTrigger)

    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: this.$root,
        start: 'top 20%',
        end: '+=500',
        pin: true,
        scrub: 1,
      },
    })

    timeline
      .to(
        this.$root,
        {
          x: '-=10%',
          transformOrigin: 'center left',
        },
        0,
      )
      .to(
        this.$refs.waveBack,
        {
          scale: 1.3,
          transformOrigin: 'center left',
        },
        0,
      )
      .to(
        this.$refs.waveFront,
        {
          scale: 1.7,
          y: '-=7%',
          transformOrigin: 'center left',
        },
        0,
      )
  },
}))
