import { gsap } from 'gsap'
import Swiper from 'swiper'
import { EffectFade } from 'swiper/modules'

import 'swiper/css'
import 'swiper/css/effect-fade'

import { defineComponent } from '~/scripts/utils/alpine'

export default defineComponent(() => ({
  descriptionSwiperInstance: undefined as Swiper | undefined,
  currentDoctor: 0,
  zIndexes: [] as number[],
  init() {
    const targets = gsap.utils.toArray<HTMLElement>(
      '[role="tab"]',
      this.$refs.doctors,
    )

    // Initial doctor is the middle one
    this.currentDoctor = Math.floor(targets.length / 2)

    // Set z-indexes as per the current active slide (currentDoctor value)
    this.zIndexes = this.getZIndexes(targets, this.currentDoctor)
    this.setZIndexes(targets, this.zIndexes)

    // Initialize description swiper
    this.descriptionSwiperInstance = new Swiper(this.$refs.descriptionSwiper, {
      effect: 'fade',
      fadeEffect: { crossFade: true },
      autoHeight: true,
      allowTouchMove: false,
      modules: [EffectFade],
      initialSlide: this.currentDoctor,
      on: {
        slideChange: (swiper) => {
          this.currentDoctor = swiper.activeIndex
        },
      },
    }) as Swiper
  },
  getZIndexes(targets: HTMLElement[], currentIndex: number) {
    const zDistributor = gsap.utils.distribute({
      base: targets.length,
      each: -1,
      from: currentIndex,
    })
    return targets.map((target, index) => zDistributor(index, target, targets))
  },
  setZIndexes(targets: HTMLElement[], zIndexes: number[]) {
    gsap.set(targets, { zIndex: (index) => zIndexes[index] })
  },
  selectDoctor() {
    if (!this.$el.dataset.index) return
    const index = Number.parseInt(this.$el.dataset.index)

    if (this.currentDoctor === index) return
    if (!this.descriptionSwiperInstance) return

    // Animation summary:
    // - spread out the doctors,
    // - change z-index
    // - group them back together
    const tl = gsap.timeline()

    const targets = gsap.utils.toArray<HTMLElement>(
      '[role="tab"]',
      this.$refs.doctors,
    )
    const total = targets.length
    const spaceBetween = 80

    // A function to calculate the offset of each doctor when spread out
    const xDistributor = gsap.utils.distribute({
      base: (spaceBetween * -1 * (total - 1)) / 2,
      each: spaceBetween,
      from: 'start',
    })

    const newZIndexes = this.getZIndexes(targets, index)

    tl.to(targets, {
      // We want to only animate the doctors that are getting a different z-index
      x: (index, target, targets) =>
        this.zIndexes[index] === newZIndexes[index]
          ? // z-index is the same, no need to move
            0
          : // z-index is different, move away
            xDistributor(index, target, targets),
      duration: 0.4,
      ease: 'power3.inOut',
      onStart: () => {
        // Change active slide
        this.descriptionSwiperInstance!.slideTo(index)
      },
      onComplete: () => {
        // Update z-indexes
        this.setZIndexes(targets, newZIndexes)
        this.zIndexes = newZIndexes
      },
    })
      // Reset doctors' positions
      .to(targets, { x: 0, duration: 0.7, ease: 'power2.inOut' })
  },
}))
