import Swiper from 'swiper'
import { A11y, EffectFade, Navigation, Thumbs } from 'swiper/modules'

import 'swiper/css'
import 'swiper/css/a11y'
import 'swiper/css/effect-fade'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'

import { useElementSize } from '~/scripts/composables/useElementSize'
import { defineComponent } from '~/scripts/utils/alpine'
import screens from '~/config/screens.json'

export default defineComponent(() => ({
  iconCardHeight: 0,
  isLocked: false,
  init() {
    const { descriptions, next, prev, services } = this.$refs

    const updateAriaSelected = ({ activeIndex, slides }: Swiper) => {
      for (const [index, slide] of slides.entries()) {
        slide.setAttribute(
          'aria-selected',
          index === activeIndex ? 'true' : 'false',
        )
      }
    }

    const descriptionsSwiper = new Swiper(descriptions, {
      effect: 'fade',
      loop: true,
      autoHeight: true,
      slidesPerView: 1,
      modules: [A11y, EffectFade],
      allowTouchMove: false,
    })

    new Swiper(services, {
      slidesPerView: 1.7,
      centeredSlides: true,
      spaceBetween: 0,
      slideToClickedSlide: true,
      loop: true,
      modules: [A11y, Navigation, Thumbs],
      navigation: { nextEl: next, prevEl: prev },
      thumbs: { swiper: descriptionsSwiper },
      breakpoints: {
        [Number.parseInt(screens.sm)]: {
          slidesPerView: 2.5,
        },
        [Number.parseInt(screens.lg)]: {
          slidesPerView: 4,
          spaceBetween: 40,
          thumbs: { swiper: undefined },
        },
        [Number.parseInt(screens.xl)]: {
          slidesPerView: 4,
          spaceBetween: 64,
        },
        [Number.parseInt(screens['2xl'])]: {
          slidesPerView: 5,
          spaceBetween: 64,
        },
      },
      on: {
        init: (swiper) => {
          updateAriaSelected(swiper)
        },
        activeIndexChange: (swiper) => {
          updateAriaSelected(swiper)
        },
        lock: () => {
          this.isLocked = true
        },
        unlock: () => {
          this.isLocked = false
        },
      },
    })

    useElementSize(
      services.querySelector('.icon-card')!,
      (size) => (this.iconCardHeight = size.height),
    )
  },
}))
