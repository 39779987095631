import { defineMagic } from '~/scripts/utils/alpine'
import { useToast } from '~/scripts/composables/useToast'

const $toast = useToast

export default defineMagic((_element, options) =>
  $toast(options.Alpine.store('toast')),
)

// Declare $toast as a magic property
declare module 'alpinejs' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  export interface Magics<T> {
    $toast: ReturnType<typeof $toast>
  }
}
