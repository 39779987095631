import rosetta from 'rosetta'

const messages = {
  en: {
    clipboard: {
      success: 'Copied to clipboard',
      error: 'An error has occurred',
      notAvailable: 'This feature is not available in this browser',
    },
  },
  fr: {
    clipboard: {
      success: 'Copié dans le presse-papier',
      error: 'Une erreur est survenue',
      notAvailable:
        "Cette fonctionnalité n'est pas disponible dans ce navigateur",
    },
  },
}

const defaultLocale = 'en'

export function useI18n(
  locale: string | (() => string) = () => document.documentElement.lang,
) {
  const locales = Object.keys(messages)
  const i18n = rosetta(messages)
  const currentLocale = typeof locale === 'function' ? locale() : locale
  const matchingLocale = locales.find(
    (locale) => locale === currentLocale.split('-')[0],
  )
  i18n.locale(matchingLocale || defaultLocale)
  return {
    ...i18n,
    t: (
      key: string | (string | number)[],
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      parameters?: any[] | Record<string, any> | undefined,
      lang?: string | undefined,
    ) => {
      const value = i18n.t(key, parameters, lang)
      if (value === '') return key
      return value
    },
  }
}
