import Alpine from 'alpinejs'
import { useElementSize } from '~/scripts/composables/useElementSize'
import setScrollbarWidth from '~/scripts/tailwindcss/plugins/spacing-container/set-scrollbar-width'
import { setRootCSSVariable } from '~/scripts/utils/css'

export default {
  init() {
    // Effect of scrollLockedBy
    Alpine.effect(() => {
      const locked = this.scrollLockedBy.length > 0
      const html = document.documentElement
      html.classList[locked ? 'add' : 'remove']('lock-scroll')

      if (window.lenis) {
        window.lenis[locked ? 'stop' : 'start']()
      }
    })

    // Effect of headerHeight and wpAdminBarHeight
    Alpine.effect(() => {
      this.offsetTop =
        this.headerHeight + (this.wpAdminBarFixed ? this.wpAdminBarHeight : 0)
      setRootCSSVariable('offset-top', `${this.offsetTop}px`)
      this.scrollPaddingTop = this.offsetTop
      setRootCSSVariable('scroll-padding-top', `${this.scrollPaddingTop}px`)
    })

    // Observe WP Toolbar height
    const wpAdminBar = document.querySelector<HTMLElement>('#wpadminbar')
    if (wpAdminBar) {
      useElementSize(wpAdminBar, (size) => {
        this.setWpToolbarHeight(size.height)
      })
    }

    // Observe WP Toolbar fixed
    const wpAdminBarFixedMediaQuery = window.matchMedia('(min-width: 601px)')
    this.setWpToolbarFixed(wpAdminBarFixedMediaQuery.matches)
    wpAdminBarFixedMediaQuery.addEventListener('change', (event) => {
      this.setWpToolbarFixed(event.matches)
    })
  },

  // Lock scroll
  scrollLockedBy: [] as string[],
  lockScrollBy(key: string) {
    this.scrollLockedBy.push(key)
    setTimeout(() => {
      setScrollbarWidth()
    }, 100)
  },
  unlockScrollBy(key: string) {
    this.scrollLockedBy = this.scrollLockedBy.filter((item) => item !== key)
    setTimeout(() => {
      setScrollbarWidth()
    }, 100)
  },

  // Header
  headerVisible: true,
  headerHeight: 0,
  setHeaderHeight(value: number) {
    this.headerHeight = value
    setRootCSSVariable('header-height', `${value}px`)
  },
  setHeaderVisible(value: boolean) {
    if (this.headerVisible !== value) {
      this.headerVisible = value
      setRootCSSVariable('header-visible', value ? '1' : '0')
    }
  },

  // WP Toolbar
  wpAdminBarFixed: false,
  wpAdminBarHeight: 0,
  setWpToolbarHeight(value: number) {
    this.wpAdminBarHeight = value
    setRootCSSVariable('wp-admin-bar-height', `${value}px`)
  },
  setWpToolbarFixed(value: boolean) {
    this.wpAdminBarFixed = value
    setRootCSSVariable('wp-admin-bar-fixed', value ? '1' : '0')
  },

  // Offset
  offsetTop: 0,
  scrollPaddingTop: 0,

  // Menu
  menuVisible: false,
  setMenuVisible(value: boolean) {
    if (this.menuVisible !== value) {
      this.menuVisible = value
      if (value) {
        this.lockScrollBy('menu')
      } else {
        this.unlockScrollBy('menu')
      }
    }
  },
  toggleMenu() {
    this.setMenuVisible(!this.menuVisible)
  },
}
