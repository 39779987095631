import gsap from 'gsap'
import { defineComponent } from '~/scripts/utils/alpine'
import { scrollToNextSection } from '~/scripts/utils/scroll'

export default defineComponent(() => ({
  init() {
    const background = this.$root.dataset.background

    if (background === 'home') {
      this.animateHomeBackground()
    }

    if (background === 'injections') {
      this.animateInjectionsBackground()
    }
  },
  scrollToNextSection() {
    scrollToNextSection(this.$root)
  },
  animateHomeBackground() {
    const { needle, needleLiquid } = this.$refs
    if (!needle) {
      return
    }
    const timeline = gsap.timeline({ delay: 1, repeat: 10, repeatDelay: 4 })

    timeline.from(needle, {
      duration: 1,
      x: -40,
      y: -40,
      ease: 'power3.inOut',
    })

    timeline.to(needleLiquid, {
      duration: 6,
      scaleY: 0,
      transformOrigin: 'bottom',
      ease: 'power2.out',
    })
  },
  animateInjectionsBackground() {
    const { hand, needleLiquid, mouth } = this.$refs

    if (!hand || !needleLiquid || !mouth) {
      return
    }
    const timeline = gsap.timeline({ delay: 1, repeat: 10, repeatDelay: 4 })

    timeline.from(hand, {
      duration: 1,
      x: 40,
      ease: 'power3.inOut',
    })

    timeline.to(
      needleLiquid,
      {
        duration: 6,
        scaleX: 0,
        transformOrigin: 'left',
        ease: 'power2.out',
      },
      1,
    )

    timeline.to(
      mouth,
      {
        duration: 6,
        scaleY: 1.3,
        scaleX: 1.1,
        transformOrigin: 'center',
      },
      1.5,
    )
  },
}))
