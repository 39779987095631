import Swiper from 'swiper'
import 'swiper/css'
import 'swiper/css/effect-fade'

import { defineComponent } from '~/scripts/utils/alpine'
import screens from '~/config/screens.json'

const SLIDES_PER_VIEW = 1.7

export default defineComponent(() => ({
  swiperInstance: undefined as Swiper | undefined,
  init() {
    this.swiperInstance = new Swiper(this.$refs.swiper, {
      centeredSlides: true,
      slidesPerView: SLIDES_PER_VIEW,
      spaceBetween: 20,
      loop: true,
      grabCursor: true,
      breakpoints: {
        [Number.parseInt(screens.sm)]: {
          slidesPerView: 2.2,
        },
        [Number.parseInt(screens.md)]: {
          slidesPerView: 2.7,
        },
        [Number.parseInt(screens['2xl'])]: {
          loop: false,
          slidesPerView: 'auto',
        },
      },
    })

    // disable swiper if not enough slides
    if (this.swiperInstance.slides.length <= SLIDES_PER_VIEW) {
      this.swiperInstance.disable()
    }
  },
}))
