import '~/styles/main.css'
import '~/styles/components/index.css'
import App from '~/scripts/App'
import setScrollbarWidth from '~/scripts/tailwindcss/plugins/spacing-container/set-scrollbar-width'

document.addEventListener('DOMContentLoaded', async () => {
  await document.fonts.ready
  App()
})

const scrollbarWidthEvents = ['load', 'resize']
for (const event of scrollbarWidthEvents) {
  window.addEventListener(event, () => {
    setScrollbarWidth()
  })
}
setScrollbarWidth()
