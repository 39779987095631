import { defineMagic } from '~/scripts/utils/alpine'
import { useToast } from '~/scripts/composables/useToast'
import { useI18n } from '~/scripts/composables/useI18n'

export default defineMagic(() => {
  return async (subject: string) => {
    if (!navigator.clipboard) {
      useToast().info({
        message: useI18n().t('clipboard.notAvailable') as string,
      })
      return
    }

    try {
      await navigator.clipboard.writeText(subject)

      useToast().success({
        message: useI18n().t('clipboard.success') as string,
      })
    } catch (error) {
      useToast().error({
        message: useI18n().t('clipboard.error') as string,
        error: error as Error,
      })
    }
  }
})
